/* eslint-disable camelcase */
export default class Generation {
  description;
  end_year;
  name;
  start_year;

  constructor() {
    this.description = "";
    this.end_year = null;
    this.name = "";
    this.start_year = null;
  }
}