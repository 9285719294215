<script>
import generationsService from '@/services/generations.service'
import Generation from '@/data/models/Generation'
import EditLayout from '@/layout/EditLayout'

export default {
  name: 'SingleGeneration',
  components: {
    EditLayout
  },
	data() {
		return {
      item: new Generation,
		}
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
	mounted() {
    !this.isNew && this.id && this._get()
	},
	methods: {
    _get(type = null) {
      generationsService.getById({ id: this.id })
      .then(res => {
        this.item = res.data
      })
    },
    create() {
      generationsService.create({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('generation') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$router.push({ name: 'generations' })
        })
    },
    edit() {
      generationsService.edit({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('generation') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
	}
}
</script>

<template>
  <EditLayout 
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    @submit="isNew ? create() : edit()"
  >
    <div slot="sidebar-middle">
      <BaseInput
        v-model="item.start_year"
        label="Start Year"
        placeholder="Enter Start Year"
        type="number"
      />
      <BaseInput
        v-model="item.end_year"
        label="End Year"
        placeholder="Enter End Year"
        type="number"
      />
    </div>
  </EditLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
